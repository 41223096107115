// extracted by mini-css-extract-plugin
export var gig = "styles-module--gig--2cc0e";
export var gigArtist = "styles-module--gigArtist--4d767";
export var gigDate = "styles-module--gigDate--19a52";
export var gigDay = "styles-module--gigDay--eddc7";
export var gigDetails = "styles-module--gigDetails--a2710";
export var gigExtra = "styles-module--gigExtra--6bdb4";
export var gigMonth = "styles-module--gigMonth--f0566";
export var gigTickets = "styles-module--gigTickets--7b646";
export var gigVenue = "styles-module--gigVenue--90ed7";
export var gigWrapper = "styles-module--gigWrapper--90910";
export var linkWrapper = "styles-module--linkWrapper--cd6af";
export var readMore = "styles-module--readMore--20039";
export var tourDates = "styles-module--tourDates--b06d6";